<template>
  <v-app-bar height="100px" color="white" flat app fixed clipped-left>
    <router-link class="logo" to="/">
      <img class="logo-image" height= "50px" src="@/assets/img/logo.svg" alt="">
      <div class="font-weight-bold primary--text no-space">Administración</div>
    </router-link>
    <v-spacer></v-spacer>
    <v-app-bar-nav-icon @click="showDrawer = !showDrawer" class="d-md-none"></v-app-bar-nav-icon>
  </v-app-bar>
</template>

<script>
export default{
  name: 'NavBar',
  
  props: ['value'],

  computed:{
    showDrawer:{
      get(){
        return this.value;
      },
      set(newValue){
        this.$emit('input', newValue);
      }
    }
  }
}
</script>

<style>
.logo{
  text-decoration: none;
}

.no-space{
  margin-top:-8px;
}

</style>
