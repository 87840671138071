import { database, storage } from '@/firebase'
import { renamedFile } from '@/helpers/fileTool'

export const getModelList = async (model) => {
  const modelList = []
  const modelRef = database.collection(model)
  const data = await modelRef.get().then(querySnapshot => querySnapshot)

  data.forEach((doc) => {
    modelList.push({ ...doc.data(), id: doc.id })
  })

  return modelList
}

export const getModel = async (model, searchTerm) => {
  const docRef = database.collection(model).doc(searchTerm)

  const response = await docRef.get().then(doc => doc)

  if (response.exists) {
    return { ...response.data(), id: response.id }
  } else {
    throw Object.assign({ code: 404, message: model + ' no found' })
  }
}

export const saveImage = async (image, folder="") => {
  var storageRef = storage.ref(folder);

  const renamedImage = await renamedFile(image, folder);
  var spaceRef = storageRef.child(renamedImage.name);
  
  await spaceRef.put(renamedImage);
  const response = await getUrl(renamedImage.name, folder);
  
  return response;
}

export const updateImage = async (file, url, folder="") => {
  const fileRef = storage.refFromURL(url)
  
  await fileRef.put(file);
  const response = await getUrl(fileRef.name, folder);

  return response;
}

export const removeImage = async (url) => {
  const fileRef = storage.refFromURL(url)
  
  const response  = await fileRef.delete();
  return response;
}


export const getUrl = async (fileName, ref) => {
  var storageRef = storage.ref(ref);

  const url = await storageRef.child(fileName).getDownloadURL();
  return url;
}