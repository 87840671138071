import { auth } from "../firebase"

export const signUp = async ( {email, password, passwordConfirm} ) => {
    try {
        if(passwordConfirm != password) throw ({code: 'auth/passwords-no-match'})

        const response = await auth.createUserWithEmailAndPassword(email, password);
        const user = response.user;
        return user;
    } catch (error) {
        if(error.code == 'auth/email-already-in-use')
            throw ({formError: true, message:'El correo está siendo usado por otro usuario'});
        else if(error.code == 'auth/invalid-email')
            throw ({formError: true, message:'Correo inválido'});
        else if(error.code == 'auth/passwords-no-match')
            throw ({formError: true, message: 'Las contraseñas deben coincidir'});
        else if(error.code == 'auth/weak-password')
            throw ({formError: true, message: 'Contraseña debíl, debe contener 6 caracteres'});
        else
            throw error;
    }
}

export const signIn = async ( {email, password} ) => {
    try {
        const response = await auth.signInWithEmailAndPassword(email, password);
        const user = response.user;
        return user;
    } catch (error) {
        if(error.code == 'auth/wrong-password')
            throw ({formError: true, message:'La contraseña es inválida'});
        else if(error.code == 'auth/invalid-email')
            throw ({formError: true, message:'Correo inválido'});
        else if(error.code == 'auth/user-not-found')
            throw ({formError: true, message:'La dirección de correo electrónico no se encuentra registrada'});
        else if(error.code == 'auth/too-many-requests')
            throw ({formError: true, message:'Has excedido el número de intentos permitidos, consulta con sistemas'});
        else if(error.code == 'auth/user-disabled')
            throw ({formError: true, message:'Tu cuenta ha sido deshabilitada por el administrador'});
        else
            throw error;
        }
    }
    
    export const signOut = async () => {
        auth.signOut();
        return 'sign out successful';
    }
    
    export const passwordRecovery = async (email) => {
        try {
            if(email.length == 0)
                throw ({code: 'auth/empty-email'})

            await auth.sendPasswordResetEmail(email);
            return 'password reset email sent';
            
        } catch (error) {
            if(error.code == 'auth/user-not-found')
                throw ({formError: true, message:'Verifica tu correo, parece que no estas registrado'});
            else if(error.code == 'auth/invalid-email')
                throw ({formError: true, message:'Correo inválido'});
            else if(error.code == 'auth/empty-email')
                throw ({formError: true, message:'Ingresa tu correo en la parte de arriba'});
            else
                throw error;
    }
}
