<template>
  <v-navigation-drawer
    v-model="showDrawer"
    clipped
    app
    bottom
    mobile-breakpoint=960
  >
    <v-row
      no-gutters
    >
      <v-list class="grow" dense nav>
        <v-list-item-group color="secondary" >
          <list-item title="Productos" icon="mdi-stack-overflow" url="/"/>
          <list-item title="Agregar producto" icon="mdi-archive-plus-outline" url="/agregar-producto"/>
          <list-item title="Banners" icon="mdi-panorama-variant-outline" url="/banners"/>
          <list-item title="Salir" icon="mdi-logout" @onClick="logout"/>
        </v-list-item-group>
      </v-list>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
  import ListItem from '../components/ListItem.vue';

  export default {
    name: 'navDrawer',
    
    components: { ListItem },

    props: ['value'],

    methods: {
      async logout(){
        try {
          await this.$store.dispatch('user/signOut');
        } catch (error) {
          console.log(error)
        }

        this.$router.push({ name: 'login' });
      }
    },

    computed:{
      showDrawer:{
        get(){
          return this.value;
        },
        set(newValue){
          this.$emit('input', newValue);
        }
      }
    }
  }
</script>

<style>
  .v-navigation-drawer__border {
    width: 0px!important;
  }
</style>
