const findBanner = (banners, productId) => {
    return banners.findIndex(banner => {
        return banner.id == productId;
    });
}

export const SET_BANNERS = (state, data) => {
    state.banners = (data)
}

export const ADD_BANNER = (state, data) => {
    state.banners.push(data)
}

export const UPDATE_BANNER = (state, data) => {
    const bannerIndex = findBanner(state.banners, data.id);
    state.banners.splice(bannerIndex, 1, data);
}

export const REMOVE_BANNER = (state, data) => {
    const bannerIndex = findBanner(state.banners, data.id);
    console.log(bannerIndex);
    state.banners.splice(bannerIndex, 1);
}
