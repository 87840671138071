import { v4 } from 'uuid';

const aceptedFiles = ['png', 'jpg', 'jpeg', 'webp'];

export const getFileType = (file) => {
    return file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2);
    // return file.type.split('/')[1] || null;
}

export const validFile = (file) => {
    const fileType = getFileType(file);
    return aceptedFiles.includes(fileType);
}

export const renamedFile = (file) => {
    const fileType = getFileType(file);
    const newFileName = v4() + '.' + fileType;

    return new File([file],  newFileName , {type: 'image/' + fileType});
}

export const getAceptedFilesString = () => {
    return aceptedFiles.join(', ')
}