<template>
    <div>
        <v-list-item link :to="url" @click="$emit('onClick')">
            <v-list-item-icon>
                <v-icon right>{{icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title class="font-weight-bold primary--text">{{title}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </div>
</template>
<script>

export default {
    name: 'listItem',

    props:['title', 'icon', 'url']
}
</script>
