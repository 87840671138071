import { getProducts, getProduct, saveProduct, removeProduct, storeImage as stImage, replaceImage } from '@/api/products'


export const fetchProducts = async(context) => {
    if(context.state.products.length <= 0) {
        const products = await getProducts()
        context.commit('SET_PRODUCTS', products)
    }
}

export const fetchProduct = async (context, searchTerm) => {
    const product = await getProduct(searchTerm)
    return product
}

export const storeProduct = async (context, productInfo) => {
    const product = await saveProduct(productInfo)
    context.commit('ADD_PRODUCT', product)
}

export const updateProduct = async (context, productInfo) => {
    const product = await saveProduct(productInfo, true)
    context.commit('UPDATE_PRODUCT', product)
}

export const deleteProduct = async (context, productInfo) => {
    await removeProduct(productInfo, true)
    context.commit('REMOVE_PRODUCT', productInfo)
}

export const storeImage = async (context, file) => {
    const response = await stImage(file)
    return response;
}

export const updateImage = async (context, { newImage, oldImage } ) => {
    const response = await replaceImage(newImage, oldImage)
    return response;
}

export const getMaterials = async () => { //it could be async in future
    return [
        'Carnaza',
        'Piel',
        'Piel sintética',
        'Hule'
    ]
}

export const getCategories = async () => { //it could be async in future
    return [
        'Guantes',
        'Guantaletas',
        'Pantalones',
        'Chamarra',
    ]
}

