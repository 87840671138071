import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'

import firebaseConfig from './firebaseconfig.json';

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const database = firebaseApp.firestore();

export const storage = firebaseApp.storage(process.env.VUE_APP_STORAGE_ENDPOINT);

firebaseApp.auth().languageCode = 'es';

export const auth = firebaseApp.auth();
