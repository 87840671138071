<template>
  <button :style="cssVars" class="second-button" @click="$emit('onClick')">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'secondButton',

  props: {
    color: {
      type: String,
      default: '#747473'
    }
  },

  computed: {
    cssVars(){
      return {
        '--color': this.color
      }
    }
  }
}
</script>

<style scoped>
.second-button {
  width: 100%;
  background: var(--color);
  color: white;
  padding: 10px 20px;
  border-radius:5px;
  transition: 0.2s ease-in-out;
}

.second-button:hover {
  background: black;
  color: white;
  transition: 1s ease;
}

</style>
