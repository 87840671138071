<template>
  <v-app class="main-font">
    <navbar v-model="drawer" v-if="isLoggedIn && showNav"></navbar>
    <drawer v-model="drawer" v-if="isLoggedIn && showNav"></drawer>
    <v-main>
      <v-container fluid>
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import Drawer from './layouts/NavDrawer.vue';
import Navbar from './layouts/TheNavbar.vue'

export default {
  components: { Navbar, Drawer },
  name: 'App',

  data: () => ({
    drawer: true,
  }),

  computed:{
    ...mapGetters('user', ['isLoggedIn']),

    showNav(){
      return this.$route.name != 'notFound';
    }
  },

  watch:{
    isLoggedIn(newVal){ //this auto update windows tabs when user state changes
      setTimeout( () => {
        if(newVal && this.$route.meta.onlyNoAuth){
          this.$router.push({name: 'banners'})
        }
        else if(!newVal && this.$route.meta.requiresAuth ){
          this.$router.push({name: 'login'})
        }
      }, 500)
    }
  }

};
</script>

<style >
@import './assets/css/global.css';
</style>