<template>
  <v-overlay :value="show" opacity=".30" class="px-5">
    <v-sheet class="pa-5" rounded="lg" color="white" max-width="400px">
      <h1 class="text-md black--text">{{ title }}</h1>
      <v-row class="mt-3">
        <v-col>
          <second-button @onClick="$emit('cancel')">
            cancelar
          </second-button>
        </v-col>
        <v-col>
          <second-button color="red" @onClick="$emit('accept')">
            Eliminar
          </second-button>
        </v-col>
      </v-row>
    </v-sheet>
  </v-overlay>
</template>

<script>
import SecondButton from './SecondButton'

export default {
  name: 'RemoveModal',

  props: {
    show: {
      type: Boolean,
      required: true,
    },

    title: {
      type: String,
      default: '¿Estás seguro?'
    }
  },

  components: {
    SecondButton,
  },
}
</script>
