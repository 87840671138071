import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from "vuex-persistedstate";

import user from './user'
import product from './product'
import banner from './banner'

Vue.use(Vuex)

// const dataState = createPersistedState({
//   paths: ['user']
// })


export default new Vuex.Store({
  modules: {
    user,
    product,
    banner
  },
  // plugins: [dataState],

  // enable strict mode (adds overhead!)
  // for dev mode only
  strict: process.env.DEBUGGING
})
