import { getModelList, getModel, saveImage, updateImage, removeImage } from './generlApi'
import { database } from '@/firebase'

export const getBanners = async () => {
  return await getModelList('banners')
}

export const getBanner = async (searchTerm) => {
  return await getModel('banners', searchTerm)
}

export const saveBanner = async (bannerInfo, updateBanner = false) => {
  
  const bannerId = !updateBanner ? database.collection('banners').doc().id : bannerInfo.id;
  
  var bannerRef = database.collection('banners').doc(bannerId);
  await bannerRef.set(bannerInfo).then(response => response);

  return {...bannerInfo, id: bannerId}
}

export const removeBanner = async (data) => {
  const response = await database.collection('banners').doc(data.id).delete().then( response => response);
  await removeImage(data.image);
  return response;
}

export const storeImage = async (file) => {
  const url = await saveImage(file, 'banners')
  return url;
}

export const replaceImage = async (file, oldImage) => {
  const url = await updateImage(file, oldImage, 'banners')
  return url;
}