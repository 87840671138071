const findProduct = (products, productId) => {
    return products.findIndex(banner => {
         return banner.id == productId;
    });
}

export const SET_PRODUCTS = (state, data) => {
    state.products = (data)
}

export const ADD_PRODUCT = (state, data) => {
    state.products.push(data)
}

export const UPDATE_PRODUCT = (state, data) => {
    const productIndex = findProduct(state.products, data.id)
    state.products.splice(productIndex, 1, data);
}

export const REMOVE_PRODUCT = (state, data) => {
    const bannerIndex = findProduct(state.products, data.id);
    state.products.splice(bannerIndex, 1);
}