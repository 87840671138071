import { getModelList, getModel, saveImage, updateImage, removeImage } from './generlApi'
import { database } from '@/firebase'

export const getProducts = async () => {
  return await getModelList('products')
}

export const getProduct = async (searchTerm) => {
  return await getModel('products', searchTerm)
}

export const saveProduct = async (productInfo, updateProduct = false) => {
  
  const productId = !updateProduct ? database.collection('products').doc().id : productInfo.id;
  
  var productRef = database.collection('products').doc(productId);
  await productRef.set(productInfo).then(response => response);

  return {...productInfo, id: productId}
}

export const removeProduct = async (data) => {
  const response = await database.collection('products').doc(data.id).delete().then( response => response);
  await removeImage(data.image);
  return response;
}

export const storeImage = async (file) => {
  const url = await saveImage(file, 'products')
  return url;
}

export const replaceImage = async (file, oldImage) => {
  const url = await updateImage(file, oldImage, 'products')
  return url;
}