<template>
  <div :class="name">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'GcmContainer',

  props: {
    name: {
      type: String,
      default: 'main-container'
    }
  }
}
</script>
