export const SET_USER = (state, data) => {
    state.userData = {
        token: data.refreshToken,
        email: data.email,
        emailVerified: data.emailVerified,
        name: data.displayName,
    };
}

export const UNSET_USER = (state) => {
    state.userData = null;
}

export const SET_IS_AUTH_READY = (state, status) => {
    state.authReady = status;
}
