<template>
  <div>
    <container name="content-container" class="my-10s my-md-1s5">
      <h1 class="txt-title" v-if="productList.length > 0">Lista de productos</h1>
      <div v-else>
        <h1 class="txt-title">Lista de productos vacía</h1>
        <router-link class="secondary--text" :to="{name: 'addProduct'}">Agregar productos</router-link>
      </div>
      <section id="product-index">
        <v-container ref="container" fill-height>
            <v-row v-if="productList.length > 0" class="fill-height" no-gsutters>
              <v-col
                v-for="(product, index) in productList"
                :key="index"
                :class="index == 1 ? 'bg-blawck' : ''" 
                cols="12"
                sm="6"
                md="6"
                lg="4"
              >
                <v-row no-gutters class="fill-height" justify="center">
                  <product-card :data="product" class="on-losad" />
                </v-row>
              </v-col>
            </v-row>
        </v-container>
      </section>
    </container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import ProductCard from '@/components/ProductCard'
  import Container from '@/components/Container'
  
  export default {
    name: 'HomeView',

    components: {
      ProductCard,
      Container,
    },

    created(){
      this.fetchProducts();
    },

    methods: {
      ...mapActions('product', ['fetchProducts']),
    },

    computed: {
      ...mapGetters('product', ['productList']),
    }
  }
</script>
