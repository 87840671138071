<template>
  <button :style="cssVars" class="main-button">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'MainButton',

  props: {
    width: {
      type: String,
      default: '100%'
    }
  },

  computed: {
    cssVars () {
      return {
        '--width': this.width
      }
    }
  }
}
</script>

<style>
.main-button {
  width: var(--width);
  background: #FFEB3B;
  padding: 10px 20px;
  border-radius: 5px;
  transition: 0.2s ease-in-out;
}

.main-button:hover {
  background: #000000;
  color: white;
  transition: 0.3s ease-in-out;
}

</style>
