import { getBanners, getBanner, saveBanner, removeBanner, storeImage as stImage, replaceImage } from '@/api/banners'


export const fetchBanners = async(context) => {
    if(context.state.banners.length <= 0) {
        const banners = await getBanners()
        context.commit('SET_BANNERS', banners)
    }
}

export const fetchBanner = async (context, searchTerm) => {
    const product = await getBanner(searchTerm)
    return product
}

export const storeBanner = async (context, bannerInfo) => {
    const product = await saveBanner(bannerInfo)
    context.commit('ADD_BANNER', product)
}

export const updateBanner = async (context, bannerInfo) => {
    const product = await saveBanner(bannerInfo, true)
    context.commit('UPDATE_BANNER', product)
}

export const deleteBanner = async (context, bannerInfo) => {
    await removeBanner(bannerInfo, true)
    context.commit('REMOVE_BANNER', bannerInfo)
}

export const storeImage = async (context, file) => {
    const response = await stImage(file)
    return response;
}

export const updateImage = async (context, { newImage, oldImage } ) => {
    const response = await replaceImage(newImage, oldImage)
    return response;
}
