<template>
  <div class="gcm-card product-car ">
    <v-row no-gutters justify="center">
      <div>
        <v-img height="150px" width="150px" :src="data.image" contain />
      </div>
    </v-row>
    <div class="divider" />
    <div>
      <h1 class="text-subtitle py-3">
        {{ data.name }}
      </h1>
      <p class="text-md line-height-md two-lines-text">
        {{ data.description }}
      </p>
    </div>
    <router-link :to="{name: 'updateProduct', params: {id: data.id}}">
      <main-button>
        Editar
      </main-button>
    </router-link>
    <second-button class="mt-3" color="red" @onClick="userTryingRemove=true">
      Eliminar
    </second-button>

    <remove-modal :show="userTryingRemove" :title="'Estas a punto de eliminar ' + data.name" @cancel="userTryingRemove=false" @accept="removeProduct"/>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import MainButton from './MainButton'
import RemoveModal from './RemoveModal'
import SecondButton from './SecondButton'

export default {
  name: 'ProductCard',

  components: {
    MainButton,
    SecondButton,
    RemoveModal
  },

  props: {
   data: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      userTryingRemove: false,
    }
  },

  methods: {
    ...mapActions('product', ['deleteProduct']),
    
    async removeProduct(){
      try {
        this.userTryingRemove = false;
        await this.deleteProduct(this.data);
      } catch (error) {
        console.log('error', error)
      }
    }
  }
}
</script>

<style>
.product-car {
  width: 100%;
  height: 100%;
  max-width: 355px;
}

.two-lines-text {
  overflow: hidden;
  width:100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
