import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: "000000", 
            secondary: "#fdd835",
            secondaryLight: "#fde735",
            accent: "#bde6fe",
            error: "#D8000C",
            errorLight: "#f3bebe",
          },
        },
    },
});
