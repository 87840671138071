import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../store/index'
import Home from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/agregar-producto',
    name: 'addProduct',
    component: () => import(/* webpackChunkName: "about" */ '../views/AddProduct.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/actualizar-producto/:id',
    name: 'updateProduct',
    component: () => import(/* webpackChunkName: "about" */ '../views/UpdateProduct.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/banners',
    name: 'banners',
    component: () => import(/* webpackChunkName: "about" */ '../views/Banners.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta: { onlyNoAuth: true }
  },
  
  {
    path: '/*',
    name: 'notFound',
    component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const onlyNoAuth = to.matched.some(record => record.meta.onlyNoAuth);
  let isAuthReady = Store.getters['user/isAuthReady'];

  //if auth is not ready it means that the user has not been set yet, so it makes recursivity until auth is ready
  const makeRouteValidation = () => { 
    isAuthReady = Store.getters['user/isAuthReady'];

    if(!isAuthReady) {
      setTimeout(makeRouteValidation, 500);
    } else {
      const isAuthenticated = Store.getters['user/isLoggedIn'];
      if(requiresAuth && !isAuthenticated)
        next({ name: 'login' });
      else if(onlyNoAuth && isAuthenticated)
        next({ name: 'Home' });
      else
        next();
    }
  }
  
  makeRouteValidation();
})

export default router
