import { signUp as register, signIn as login,  signOut as logout, passwordRecovery } from '@/api/auth'
import { auth } from '@/firebase'

export const signUp = async (context, credentials) => {
    const user = await register(credentials);
    context.commit('SET_USER', user);
    return user;
}

export const signIn = async (context, credentials) => {
    const user = await login(credentials);
    context.commit('SET_USER', user);
    return user;
}

export const signOut = async (context) => {
    const response = await logout();
    context.commit('UNSET_USER');
    return response;
}

export const passwordReset = async (context, email) => {
    const response = await passwordRecovery(email);
    return response;
}

export const watchForAuthState = (context) => {
    auth.onAuthStateChanged((user) =>{
        context.commit('SET_IS_AUTH_READY', true);

        if(user){
            context.commit('SET_USER', user);
        }else{
            context.commit('UNSET_USER');                  
        }
    });
}
